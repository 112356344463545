.accordion {
  position: relative;
  padding: 0.75rem;
}

.accordion__item {
  margin-top: 0.5rem;
}

.accordion__title-wrapper {
  display: grid;
  grid-template-columns: 1fr clamp(5%, 5rem, 10%);
  align-items: center;

  position: relative;
  width: 100%;

  box-shadow: var(--control-shadow);
  background-color: var(--dark2);
  border-radius: 1rem;
  padding: var(--cell-padding);
  margin-bottom: 1rem;

  transition: 0.5s ease;
}

.accordion__title-wrapper:hover,
.accordion__title-wrapper:focus,
.accordion__title-wrapper:focus-within {
  filter: brightness(1.1);
  background-color: var(--dark1);
}

.accordion__title-wrapper.--clickable:hover,
.accordion__title-wrapper.--clickable:focus {
  cursor: pointer;
  color: var(--blue1);
}

.accordion__title-element-wrapper {
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
}

.accordion__title-trigger-wrapper {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.accordion__title-trigger-wrapper.--clickable:hover,
.accordion__title-trigger-wrapper.--clickable:focus {
  cursor: pointer;
  color: var(--blue1);
}

.accordion__content-wrapper {
  overflow: hidden;
}