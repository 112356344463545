.cart-view-terminal__controls-wrapper {
  display: grid;
  grid-template-columns: 1fr 10ch 10ch;
  grid-template-rows: auto 2rem 2rem;
  gap: 3px;

  width: auto;
  padding: 0.5rem;
}

.cart-view-terminal__message {
  grid-row: 1 / 3;
  grid-column: 1 / 2;

  padding: 0.25rem;
}

.cart-view-terminal__input-wrapper {
  grid-row: 3 / 4;
  grid-column: 1 / 3;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  padding: 0.25rem;
  background-color: var(--dark1);
  box-shadow: var(--control-shadow);
  transition: 0.5s ease;
}

.cart-view-terminal__input,
.cart-view-terminal__input.--enabled:focus {
  width: 100%;
  height: auto;

  position: relative;
  border: 0;
  background-color: var(--dark1);
  color: var(--light1);
  font-family: monospace;
  transition: 0.5s ease;
}
.cart-view-terminal__input-wrapper.--enabled:hover {
  filter: brightness(1.2);
}

.cart-view-terminal__execute,
.cart-view-terminal__terminate,
.cart-view-terminal__submit {
  display: flex;
  justify-content: space-around;

  padding: 0.25rem;
  box-shadow: var(--control-shadow);
  border-radius: 3px;
  transition: 0.3s ease;
  font-weight: 600;
}
.cart-view-terminal__execute,
.cart-view-terminal__terminate {
  grid-row: 2 / 3;
}
.cart-view-terminal__submit {
  grid-row: 3 / 4;
  grid-column: 3 / 4;

  background-color: rgba(var(--blue2-components), 1);
}
.cart-view-terminal__execute:hover,
.cart-view-terminal__terminate:hover,
.cart-view-terminal__submit.--enabled:hover,
.cart-view-terminal__execute:focus,
.cart-view-terminal__terminate:focus,
.cart-view-terminal__submit.--enabled:focus {
  filter: brightness(1.1);
  cursor: pointer;
  box-shadow: none;
  text-decoration: underline;
}
.cart-view-terminal__execute {
  background-color: rgba(var(--green2-components), 1);
  grid-column: 2 / 3;
}
.cart-view-terminal__terminate {
  background-color: rgba(var(--red2-components), 1);
  grid-column: 3 / 4;
}

.cart-view-terminal__submit.--disabled {
  filter: grayscale(2);
  cursor: default;
}