.planner-search__wrapper {
  display: grid;
  grid-template-rows: auto 1fr;

  position: relative;
  width: 100%;
  height: 100%;
}

/* Results */
/* #region */

.planner-search__results {
  grid-row: 2 / 3;
  position: relative;
  overflow-y: scroll;
}

.planner-search__results > * + * {
  margin-top: 0.5rem;
}

.planner-search__page-change {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  width: 100%;
  height: 2rem;

  padding: 2rem;
}


/* #endregion */

/* Filters */
/* #region */

.planner-search__filter {
  grid-row: 1 / 2;

  display: flex;
  flex-wrap: wrap;

  position: relative;
  height: auto;
  gap: 0.5rem 1rem;

  background-color: rgba(var(--dark3-components), 0.5);
  box-shadow: var(--control-shadow);
  padding: 0.5rem;
}

/* #endregion */