.import-export__wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
  
  font-family: var(--monospace);
}

.import-export__popup-trigger {
  padding: 0.25rem;
}