.team-view__wrapper {
  --team-builder-gap: 9px;
  --team-builder-border1: 1px solid rgba(var(--light1-components), 0.6);
  --team-builder-border2: 1px solid rgba(var(--light2-components), 0.5);
  --team-builder-border3: 1px solid rgba(var(--light3-components), 0.4);
  
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: calc(2 * var(--team-builder-gap) + 80px) 1fr;
  gap: 3px;

  position: relative;
  width: 100%;
  height: 100%;

  font-family: var(--monospace);
}

.team-members__wrapper,
.member-details__wrapper,
.reference-panel__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  padding: var(--team-builder-gap)
}

.team-members__wrapper {
  grid-row: 1 / 2;
  grid-column: 1 / 8;
}

.member-details__wrapper {
  grid-row: 2 / 3;
  grid-column: 1 / 8;
}

.reference-panel__wrapper {
  grid-row: 1 / 3;
  grid-column: 8 / 13;
}

.member-details__header,
.team-member-holder__header,
.member-details__inner-header,
.reference-panel__header {
  position: absolute;
  top: -7px;

  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  color: var(--blue1);
  background-color: var(--dark3);
  padding: 0 2px 0 2px;

  z-index: 2;
}
.member-details__header,
.team-member-holder__header,
.reference-panel__header {
  left: 6px;
}
.member-details__inner-header {
  right: 6px;
}