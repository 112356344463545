.load-icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;

  opacity: 0.5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pokeball__wrapper {
  display: flex;

  position: relative;

  background-color: var(--light1);
  border-radius: 50%;
  transform-origin: center;
  overflow: hidden;

  animation: spin 2s infinite;
}

.pokeball__center {
  position: absolute;
  width: 30%;
  height: 30%;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;

  background-color: var(--light1);
  transform-origin: center;
  z-index: 1;
}
.pokeball__button {
  position: absolute;
  width: 75%;
  height: 75%;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  border: 1px solid rgba(var(--light3-components), 0.5);

  background-color: var(--light2);
  transform-origin: center;
}
.pokeball__red {
  position: absolute;
  width: 125%;
  height: 50%;

  background-color: var(--red1);
}
.pokeball__belt {
  position: absolute;
  width: 125%;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  background-color: var(--dark2);
}