.versus-matchup__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  border: 1px solid var(--light3);
  box-shadow: 2px 2px 2px 1px black;
}

.versus-matchup__table-body {
  display: grid;
  grid-template-rows: 50px repeat(6, 1fr);
  grid-template-columns: 50px repeat(6, 1fr);
  gap: 0.25rem;

  position: relative;
  width: 100%;
  min-width: calc(50px + 24rem);
  height: 100%;

  padding: 0.25rem;
  background-color: rgba(var(--dark3-components), 0.7);
  font-size: 0.9rem;
  line-height: 1.2;

  /* Box shadow variables */
  --basic-cell-shadow: 1px 1px var(--dark1);
  --emph-cell-shadow: 1px 1px var(--light3);
  --other-cell-shadow-inset: inset 1px 1px 2px 1px;
}

.versus-matchup__row {
  grid-row: span 1;
  grid-column: 1 / 8;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 50px repeat(6, 1fr);
  gap: 0.25rem;
}

.versus-matchup__icon {
  grid-row: span 1;
  grid-column: span 1;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--dark2);
}
.versus-matchup__icon.--you {
  box-shadow: inset 1px 1px 2px rgba(var(--green1-components), 0.5), var(--basic-cell-shadow);
}
.versus-matchup__icon.--them {
  box-shadow: inset 1px 1px 2px rgba(var(--red1-components), 0.5), var(--basic-cell-shadow);
}

.versus-matchup__cell {
  grid-row: span 1;
  grid-column: span 1;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;

  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  background-color: var(--dark2);
  box-shadow: var(--basic-cell-shadow);
}

.versus-matchup__icon:focus-within,
.versus-matchup__cell:focus-within {
  outline: 2px dashed var(--blue1);
} 


/* Speed tie triangles */
/* #region */

.versus-matchup__outspeed {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  
  border-bottom: 6px solid var(--green1);
}
.versus-matchup__outsped {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 6px solid var(--red1);
}
.versus-matchup__speed-tie-upper {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-bottom: 3px solid yellow;
}
.versus-matchup__speed-tie-lower {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 3px solid yellow;
}

/* #endregion */

/* Emphasis effects */
.versus-matchup__cell.--emph,
.versus-matchup__icon.--emph {
  filter: brightness(1.1);
  background-color: var(--dark1);
}
.versus-matchup__cell.--emph {
  box-shadow: var(--emph-cell-shadow);
}
.versus-matchup__icon.--you.--emph {
  box-shadow: var(--other-cell-shadow-inset) rgba(var(--green1-components), 0.7), var(--emph-cell-shadow);
}
.versus-matchup__icon.--them.--emph {
  box-shadow: var(--other-cell-shadow-inset) rgba(var(--red1-components), 0.7), var(--emph-cell-shadow);
}

/* Highlight cell on change */
.versus-matchup__cell {
  transition: filter 0.5s ease;
}
.versus-matchup__cell.--changed {
  filter: brightness(1.5);
}

/* Rate cell */
/* 1 */
.versus-matchup__cell.--1 {
  box-shadow: var(--basic-cell-shadow),
              var(--other-cell-shadow-inset) rgba(var(--red1-components), 0.7);
}
.versus-matchup__cell.--1.--emph {
  box-shadow: var(--basic-cell-shadow),
              var(--emph-cell-shadow),
              var(--other-cell-shadow-inset) rgba(var(--red1-components), 0.7);
}
/* 2 */
.versus-matchup__cell.--2 {
  box-shadow: var(--basic-cell-shadow),
              var(--other-cell-shadow-inset) rgba(255, 165, 0, 0.7);
}
.versus-matchup__cell.--2.--emph {
  box-shadow: var(--basic-cell-shadow),
              var(--emph-cell-shadow),
              var(--other-cell-shadow-inset) rgba(255, 165, 0, 0.7);
}
/* 3 */
.versus-matchup__cell.--3 {
  box-shadow: var(--basic-cell-shadow),
              var(--other-cell-shadow-inset) rgba(255, 255, 0, 0.7);
}
.versus-matchup__cell.--3.--emph {
  box-shadow: var(--basic-cell-shadow),
              var(--emph-cell-shadow),
              var(--other-cell-shadow-inset) rgba(255, 255, 0, 0.7);
}
/* 4 */
.versus-matchup__cell.--4 {
  box-shadow: var(--basic-cell-shadow),
              var(--other-cell-shadow-inset) rgba(var(--green1-components), 0.7);
}
.versus-matchup__cell.--4.--emph {
  box-shadow: var(--basic-cell-shadow),
              var(--emph-cell-shadow),
              var(--other-cell-shadow-inset) rgba(var(--green1-components), 0.7);
}
/* 5 */
.versus-matchup__cell.--5 {
  box-shadow: var(--basic-cell-shadow),
              var(--other-cell-shadow-inset) rgba(var(--blue1-components), 0.7);
}
.versus-matchup__cell.--5.--emph {
  box-shadow: var(--basic-cell-shadow),
              var(--emph-cell-shadow),
              var(--other-cell-shadow-inset) rgba(var(--blue1-components), 0.7);
}

.versus-matchup__wrapper button {
  outline: none;
}