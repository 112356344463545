.team-members__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--dark3);
}

.team-members__list {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(6, 1fr);

  position: relative;
  width: 100%;
  height: 100%;

  gap: var(--team-builder-gap);
}

/* Individual members */
/* #region */

.team-member-holder__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  
  border: var(--team-builder-border3);
  padding: var(--team-builder-gap);
  transition: 0.3s ease;
}
.team-member-holder__wrapper:hover,
.team-member-holder__wrapper:focus-within,
.team-member-holder__wrapper.--active {
  background-color: rgba(var(--light1-components), 0.05);
  border: var(--team-builder-border1);
  filter: brightness(1.1);
}

.team-member-holder__content {
  position: relative;
  width: 100%;
  height: 100%;
}

/* #endregion */