.cart-view__wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100%;

  height: 100%;
  position: relative;
}

.cart-view-accordion__cell {
  grid-column: 1 / 9;
}
.cart-view-terminal__cell {
  grid-column: 9 / 13;
}

.cart-view-accordion__cell,
.cart-view-terminal__cell {
  overflow: hidden;
  grid-row: 1 / 2;
  position: relative;

  height: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.cart-view-accordion__wrapper,
.cart-view-terminal__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  padding: 0.5rem;
  font-family: var(--monospace);
  background-color: rgba(var(--dark3-components), 0.7);
}