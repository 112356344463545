.three-toggle__wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  position: relative;
  width: fit-content;

  height: 2rem;

  line-height: 1;
  font-family: var(--monospace);
  box-shadow: var(--control-shadow);
  background-color: var(--dark2);

  padding: 0 0.5rem 0 0.5rem;
}

.three-toggle__label {
  font-size: 1.125rem;
  font-weight: 500;
  margin-right: 1rem;
}

.three-toggle__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  position: relative;
  font-size: 1rem;
}

.three-toggle__button {
  height: 1.5rem;

  width: calc(4ch + 0.5rem);
  padding: 0.125rem;
  box-shadow: var(--control-shadow);
  border-radius: 3px;
  transition: 0.3s ease;
  transform-origin: center;
}
.three-toggle__button.--disabled {
  background-color: var(--dark1);
}
.three-toggle__button:not(.--disabled):hover,
.three-toggle__button:not(.--disabled):focus,
.three-toggle__button.--active {
  filter: brightness(1.1);
  cursor: pointer;
  box-shadow: none;
  text-decoration: underline;
  transform: scale(1.1);
}
.three-toggle__button:not(.--disabled).--active:not(:hover):not(:focus) {
box-shadow: var(--control-shadow);
}

.three-toggle__null {
  background-color: var(--blue2);
}
.three-toggle__true {
  background-color: var(--green2);
}
.three-toggle__false {
  background-color: var(--red2);
}