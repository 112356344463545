.popup-wrapper {
  position: relative;
}

.popup-trigger {
  position: relative;
  width: fit-content;
  height: fit-content;

  border: 1px solid transparent;
}
.popup-trigger:hover {
  cursor: pointer;
}
.popup-trigger:hover,
.popup-trigger:focus {
  text-decoration: underline;
}

.popup-trigger.--active {
  background-color: rgba(var(--dark3-components), 0.9);
  border: 1px solid var(--light3);
  text-decoration: underline;
}

.popup-content {
  position: absolute;
  overflow: hidden;

  background-color: rgba(var(--dark3-components), 0.6);
  z-index: 100;


  border: 1px solid var(--light3);
  
  /* Need to use opacity; just setting height to 0 or auto will still show the borders */
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}
.popup-content.popup-content.--active {
  opacity: 1;
  pointer-events: auto;
}

/* Border effects */
/* #region */

/* Box shadow around active content */
.popup-content.--active {
  box-shadow: 6px 6px 4px 0 rgba(0, 0, 0, 0.4);
}

/* #endregion */

.popup-padder {
  position: relative;

  padding: 1rem;
}