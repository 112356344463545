.quick-search__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  /* Extra 6px cancels out the box-shadow applied below */
  padding: 2rem 2rem 5rem calc(2rem + 6px);

}

.quick-search__results {
  margin-top: 1rem;

  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;

  height: 100%;
  width: 100%;
}
/* Box shadow on top and left matches scroll bar on bottom and right */
.quick-search__results {
  box-shadow: 
  -6px 0 0 0 var(--light3),
  0 -6px 0 0 var(--light3),
  -6px -6px 0 0 var(--light1);
}

.quick-search__entries-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.quick-search__entries-wrapper > * + * {
  margin-top: 0.5rem;
}

.quick-search__entry,
.quick-search__legend {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 10ch 60px minmax(20ch, min-content) 90px 8ch 50ch;
  align-items: center;
  gap: 0 24px;


  min-width: fit-content;
  width: auto;
  padding: 0.5rem 1rem 0.5rem 0.5rem;

  font-family: var(--monospace);
  font-size: 0.9rem;
}

.quick-search__legend {
  min-width: fit-content;
  
  background-color: var(--dark2);
  box-shadow: 0px 1px var(--dark1);
  margin-bottom: 1rem;
  font-weight: 600;
}

.quick-search__entry {
  position: relative;

  background-color: var(--dark2);
  box-shadow: 0px 1px var(--dark1);
}

.quick-search__icon,
.quick-search__name,
.quick-search__tier,
.quick-search__stat {
  position: relative;
}

.quick-search__icon {
  grid-column: 2 / 3;

  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 50px;
  height: 30px;

  border-radius: 50%;
  background-color: var(--dark1);
  box-shadow: var(--control-shadow);
}

.quick-search__name {
  grid-column: 3 / 4;
}

.quick-search__typing {
  grid-column: 4 / 5;
}
.quick-search__typing > * + * {
  margin-left: 3px;
}

.quick-search__tier {
  grid-column: 5 / 6;
}

.quick-search__stats {
  grid-column: 6 / 7;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(7, 3ch);
  gap: 48px;
}

.quick-search__stat {
  text-align: right;
}
.quick-search__legend > .quick-search__stats > .quick-search__stat {
  text-align: left;
}

.bad {
  color: var(--red1);
}

.ok {
  color: orange;
}

.decent {
  color: yellow;
}

.good {
  color: var(--green1);
}

.great {
  color: var(--blue1);
}

.quick-search__save-wrapper {
  grid-column: 1 / 2;
}

.quick-search__save-wrapper > .quick-search__save {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 0.5rem;

  width: fit-content;

  background-color: var(--dark1);
  box-shadow: var(--control-shadow);
  border-radius: 0.5rem;
  padding: 0 1rem 0 1rem;
}

.quick-search__legend > div > span:hover,
.quick-search__legend > .quick-search__stats > .quick-search__stat:hover {
  cursor: pointer;
}

/* Emphasize first entry */
.quick-search__entry:first-of-type {
  background-color: var(--dark1);
  border-top: 1px solid var(--light3);
  border-bottom: 1px solid var(--light3);
  box-shadow: none;
}
/* Change button and icon background colors to retain contrast */
.quick-search__entry:first-of-type > .quick-search__icon {
  background-color: rgba(var(--light3-components), 0.7);
}
.quick-search__entry:first-of-type > .quick-search__save-wrapper > .quick-search__save {
  background-color: rgba(var(--light3-components), 0.7);
}

.quick-search__page-change {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  width: 100%;
  height: 2rem;

  padding: 2rem;
}