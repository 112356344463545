.error-boundary__trigger {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;

  font-family: var(--monospace);
  font-weight: 500;
  color: var(--red1);
  padding: 0.125em;
}

.error-boundary__message-wrapper {
  display: flex;

  position: relative;
  width: 40ch;
  height: 8rem;

  overflow-y: scroll;
  padding: 1rem;
}

.error-boundary__message {
  color: var(--red1);
  font-family: var(--monospace);
}