.sort-switch__wrapper {
  position: absolute;
  vertical-align: center;
  top: 0;
  left: -24px;

  background-color: rgba(var(--dark3-components), 0.5);
  height: 1.2rem;
  width: 18px;
  transform-origin: center;
}
.sort-switch__wrapper:hover,
.sort-switch__wrapper:focus-within {
  filter: brightness(1.2);
  transform: scale(1.1);
}
.sort-switch__wrapper:hover {
  cursor: pointer;
}

.sort-switch__arrow-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  position: relative;

  width: 100%;
  height: 100%;
}

.sort-switch__asc {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--dark1);
}
.sort-switch__asc.--active {
  border-bottom: 6px solid var(--green1);
}

.sort-switch__desc {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--dark1);
}
.sort-switch__desc.--active {
  border-top: 6px solid var(--red1);
}