.cart-view-terminal__box-wrapper {
  display: grid;
  grid-template-columns: 7ch 1fr 4ch 3fr;
  grid-template-rows: 1fr 1fr;

  gap: 3px;
  padding: calc(0.6 * var(--cell-padding));
}

.cart-view-terminal__box-wrapper.--breaking {
  background-color: rgba(var(--red1-components), 0.2);
}

.cart-view-terminal__box-role,
.cart-view-terminal__box-role.--and,
.cart-view-terminal__box-role.--or,
.cart-view-terminal__box-move-wrapper,
.cart-view-terminal__box-remove-wrapper,
.cart-view-terminal__box-note {
  background-color: var(--dark2);
  box-shadow: var(--control-shadow);
  padding: 3px 5px 3px 5px;
  font-size: 1rem;
}

/* Role */
/* #region */

.cart-view-terminal__box-role,
.cart-view-terminal__box-role.--and,
.cart-view-terminal__box-role.--or {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  display: flex;
  align-items: center;

  color: var(--green1);
  font-weight: 700;
  font-size: 0.9rem;
  transition: 0.2s ease;
}
.cart-view-terminal__box-role.--and {
  justify-content: flex-start;
}
.cart-view-terminal__box-role.--or {
  justify-content: flex-end;
}
.cart-view-terminal__box-role-toggleable:hover,
.cart-view-terminal__box-role-toggleable:focus {
  cursor: pointer;
  filter: brightness(1.2);
}

/* #endregion */

/* Movers */
/* #region */

.cart-view-terminal__box-move-wrapper {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.cart-view-terminal__arrow-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-view-terminal__box-move-up,
.cart-view-terminal__box-move-down {
  transform-origin: center;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.cart-view-terminal__arrow-wrapper:hover > .cart-view-terminal__box-arrow,
.cart-view-terminal__arrow-wrapper:focus > .cart-view-terminal__box-arrow {
  transform: scale(1.1);
  filter: brightness(1.2);
}
.cart-view-terminal__box-move-up {
  border-bottom: 8px solid var(--blue1);
}
.cart-view-terminal__box-move-down {
  border-top: 8px solid var(--blue1);
}
.cart-view-terminal__box-move-up.--disabled {
  border-bottom: 8px solid var(--dark3);
  pointer-events: none;
}
.cart-view-terminal__box-move-down.--disabled {
  border-top: 8px solid var(--dark3);
  cursor: default;
}

/* #endregion */

/* Remover */
/* #region */

.cart-view-terminal__box-remove-wrapper {
  grid-row: 1 / 2;
  grid-column: 3 / 4;

  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-view-terminal__box-remove {
  width: 12px;
  height: 12px;

  background-color: var(--red1);
  transform-origin: center;
  border-radius: 3px;
}
.cart-view-terminal__box-remove:hover,
.cart-view-terminal__box-remove:focus {
  cursor: pointer;
  transform: scale(1.1);
  filter: brightness(1.2);
}

/* #endregion */

/* Note */
/* #region */

.cart-view-terminal__box-note {
  grid-row: 2 / 3;
  grid-column: 1 / 5;
}

.cart-view-terminal__box-note > span {
  display: block;
  margin-top: 3px;
  margin-right: 2rem;
  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* #endregion */