.cart-view-terminal__wrapper {
  justify-self: center;
  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-view-terminal__boxes-wrapper {
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.cart-view-terminal__boxes-wrapper > ul {
  position: relative;
  width: 100%;
  height: 100%;
}

.cart-view-terminal__boxes-wrapper > * + * {
  margin-top: 0.25rem;
}