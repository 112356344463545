.main-content__wrapper {
  --entry-vertical-padding: 0.5rem;
  --entry-horizontal-padding: 0.25rem;
  --entry-top-margin: 0.25rem;
}

/* Entire row */
.planner-search__entry,
.planner-accordion__entry {
  position: relative;
  
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr 3fr 4fr;
  
  width: 100%;

  overflow: hidden;
  background-color: var(--dark2);
  font-family: var(--monospace);
  padding-bottom: calc(3px + var(--entry-vertical-padding));
  transition: ease 0.25s;
}

/* Name */
.planner__entry-row-name-container {
  /* Margin instead of padding to influence icons */
  margin-left: calc(2 * var(--entry-horizontal-padding));
  padding-right: var(--entry-horizontal-padding);
}

.planner__entry-row-name {
  grid-column: 1 / 2;

  display: inline-block;

  font-weight: 500;
  text-decoration: none;
  transform-origin: top left;

}

.planner__entry-row-name-container a:focus {
  outline: none;
}
.planner-search__entry:focus-within .planner__entry-row-name,
.planner-accordion__entry:focus-within .planner__entry-row-name {
  transform: scale(1.05);
}
.planner__entry-row-name:hover,
a:focus .planner__entry-row-name {
  color: var(--blue1);
  text-decoration: underline;
  transform: scale(1.05);  
}

/* Data */
.planner-search__entry-data,
.planner-accordion__entry-data {
  grid-column: 2 / 3;

  display: grid;
  grid-template-columns: 1fr 5ch 1fr 5fr;

  height: fit-content;
  
  padding-left: var(--entry-horizontal-padding);
  padding-right: var(--entry-horizontal-padding);
}

.planner-search__entry-data-key,
.planner-accordion__entry-data-key {
  grid-row: span 1;
  grid-column: 2 / 3;

  font-weight: 700;
}

.planner-search__entry-data-value,
.planner-accordion__entry-data-value {
  grid-row: span 1;
  grid-column: 4 / 5;
}

/* Description */
/* #region */

.planner-search__entry-description,
.planner-accordion__entry-description {
  grid-column: 3 / 4;

  padding-left: var(--entry-horizontal-padding);
  padding-right: calc(4 * var(--entry-horizontal-padding));
}

/* #endregion */