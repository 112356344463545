.member-details__wrapper {
  display: grid;
  grid-template-rows: 6rem 2fr;
  grid-template-columns: 1fr 2fr;
  gap: var(--team-builder-gap);

  background-color: var(--dark3);
}
/* Hide content when inactive, i.e. member isn't selected */
.member-details__wrapper.--inactive div {
  display: none;
}

.member-details__box,
.member-details__inner-box {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;

  border: var(--team-builder-border3);
}

.member-details__content,
.member-details__inner-content {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;

  padding: var(--team-builder-gap);
}


/* Hover effects */
/* #region */

.member-details__box,
.member-details__inner-box {
  transition: 0.3s ease;
}

/* Change brightness when hovering over/focusingwithin enabled, interactive box, or when box is active */
.member-details__box.--interactive:not(.--disabled):not(.--has-inner-box):hover,
.member-details__inner-box.--interactive:not(.--disabled):hover,
.member-details__box:not(.--disabled):not(.--has-inner-box):focus-within,
.member-details__inner-box:not(.--disabled):focus-within,
.member-details__box:not(.--has-inner-box).--active,
.member-details__inner-box.--active {
  background-color: rgba(var(--light1-components), 0.05);
}

/* Add border when hovering over/focusing within enabled, interactive box */
.member-details__box.--interactive:not(.--disabled):not(.--has-inner-box):hover,
.member-details__inner-box.--interactive:not(.--disabled):hover,
.member-details__box:not(.--disabled):not(.--has-inner-box):focus-within
.member-details__inner-box:not(.--disabled):focus-within,
.member-details__box.--active,
.member-details__box:focus-within,
.member-details__inner-box.--active {
  border: var(--team-builder-border1);
}
/* When box has inner box, don't change the border so strongly */
.member-details__box.--has-inner-box.--interactive:not(.--disabled):hover,
.member-details__box.--has-inner-box.--interactive:not(.--disabled):focus-within {
  border: var(--team-builder-border2);
}

/* When hovering over enabled, interactive box/inner box, change cursor */
.member-details__box.--interactive:not(.--has-inner-box):not(.--disabled) .member-details__content:hover,
.member-details__inner-box.--interactive:not(.--disabled) .member-details__inner-content:hover {
  cursor: pointer;
}

/* #endregion */