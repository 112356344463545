.status-control__wrapper {
  min-width: calc(40px + 24ch);
}

.status-control__entry {
  display: grid;
  grid-template-columns: calc(40px + 12.5ch) 3ch 3ch;
  gap: 1.5ch;
  justify-content: center;
  align-items: center;

  position: relative;
  width: auto;
  height: clamp(1.5rem, 2rem, 5%);
}

.status-control__name,
.status-control__cause,
.status-control__resist {
  grid-column: span 1;
  align-self: center;

  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
}
.status-control__name {
  display: flex;
  justify-content: space-between;
}
/* Pad cells except icons */
.status-control__name,
.status-control__cause,
.status-control__resist {
  padding: 0 1ch 0 1ch;
}
/* Center everything except names */
.status-control__cause,
.status-control__resist {
  justify-content: center;
}

/* Background colors */

.status-control__entry:nth-of-type(2n) {
  background-color: rgba(var(--dark1-components), 0.5);
}
.status-control__entry:nth-of-type(2n + 1) {
  background-color: rgba(var(--dark2-components), 0.5);
}
.status-control__entry:not(:first-of-type) > td:not(.status-control__name):hover,
.status-control__entry:not(:first-of-type) > th:not(.status-control__name):hover,
.status-control__entry:not(:first-of-type) > td:not(.status-control__name):focus-within,
.status-control__entry:not(:first-of-type) > th:not(.status-control__name):focus-within {
  outline: 2px dashed var(--blue1);
}