.cart-view-accordion__wrapper {
  overflow-y: scroll;
}

.cart-view-accordion__title {
  font-weight: 600;
  font-size: 1.2rem;
  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.target-entity-accordion__title {
  font-weight: 400;
  font-size: 1.1rem;
  padding-left: 0.5rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}