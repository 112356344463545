/* Dropdown */

.dropdown__wrapper {
  position: relative;

  font-family: var(--monospace);
}

.dropdown__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  height: 100%;
  
  background-color: var(--dark2);
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border: none;
}

.dropdown__trigger:hover,
.dropdown__trigger:focus {
  filter: brightness(1.1);
}

.dropdown__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: monospace;
  font-weight: 500;
}
.dropdown__trigger:hover .dropdown__title,
.dropdown__trigger:focus .dropdown__title {
  text-decoration: underline;
}

.dropdown__content-wrapper {
  position: absolute;
  overflow: hidden;
  background-color: var(--dark2);
  z-index: 100;

  padding: 1rem;
}

.dropdown__content-padder {
  background-color: var(--dark1);
  box-shadow: var(--control-shadow);
  border-radius: 1rem;
  padding: 1rem;
}
