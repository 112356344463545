/* Items */
/* #region */

.planner-accordion__item {
  width: 100%;
}

/* #endregion */

/* Content */
/* #region */

.planner-accordion__content-wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;

  /* Makes it so box shadow isn't hidden */
  margin-left: calc(-1 * var(--accordion-left-shadow-size));
  padding-left: var(--accordion-left-shadow-size);

  border-radius: 1rem;
  margin-bottom: 1rem;

  overflow: hidden;
}

/* Spacing between subitems */
.planner-accordion__content-wrapper > * + * {
  margin-top: 1rem;
}

/* #endregion */

/* Subitems */
/* #region */

.planner-accordion__subitem {
  /* Shadow is absolutely positioned */
  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--dark2);
}

.planner-accordion__subitem-header {
  padding-left: var(--entry-horizontal-padding);
}

.planner-accordion__clarification {
  padding-left: var(--entry-horizontal-padding);
}

.planner-accordion__subitem-list {
  position: relative;
  width: 100%;
  height: 100%;
}

.planner-accordion__subitem-list > * + * {
  margin-top: 1rem;
}

.planner-accordion__subitem-results {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Left-hand shadow */
/* #region */

/* Blue for neutral entries */
.planner-accordion__subitem-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--accordion-left-shadow-size);
  height: 100%;
  box-shadow: calc(-1 * var(--accordion-left-shadow-size)) 0 1px 0 var(--blue1);
}

.planner-accordion__subitem-shadow.--positive,
.planner-accordion__subitem-shadow.--negative {
  position: absolute;

  width: calc(100% + var(--accordion-left-shadow-size));
  height: 100%;
}

.planner-accordion__subitem-shadow.--positive {
  box-shadow: calc(-1 * var(--accordion-left-shadow-size)) 0 1px 0 var(--green1);
}

.planner-accordion__subitem-shadow.--negative {
  box-shadow: calc(-1 * var(--accordion-left-shadow-size)) 0 1px 0 var(--red1);
}

/* #endregion */

/* #endregion */

/* Entries */
/* #region */

/* Gives illusion of gap; background of subitem is same color as entry. Don't put above first entry/second child */
.planner-accordion__entry::before {
  content: '';
  display: block;
  position: absolute;
  box-shadow: inset 0 var(--entry-top-margin) rgba(var(--dark3-components), 0.7);
  
  top: 0;
  left: 0;
  height: var(--entry-top-margin);
  width: 100%;
}
/* To compensate, we add additional padding to top of entry */
.planner-accordion__entry {
  padding-top: calc(var(--entry-top-margin) + var(--entry-vertical-padding));
  box-shadow: 1px 1px 2px 1px rgba(var(--dark1-components), 1);
}

/* Lower shadow */
/* #region */

.planner-accordion__entry::after,
.planner-accordion__subitem.--positive > .planner-accordion__entry::after,
.planner-accordion__subitem.--negative > .planner-accordion__entry::after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 3px;
  left: 0;
  bottom: 0;
  transition: 0.5s ease;
}

.planner-accordion__entry::after {
  box-shadow: inset var(--bg-shadow-inset) rgba(var(--blue1-components), 0.7);
}

.planner-accordion__subitem.--positive > .planner-accordion__entry::after {
  box-shadow: inset var(--bg-shadow-inset) rgba(var(--green1-components), 0.7);
}

.planner-accordion__subitem.--negative > .planner-accordion__entry::after {
  box-shadow: inset var(--bg-shadow-inset) rgba(var(--red1-components), 0.7);
}

.planner-accordion__entry.--expand-hover::after,
.planner-accordion__subitem.--positive > .planner-accordion__entry.--expand-hover::after,
.planner-accordion__subitem.--negative > .planner-accordion__entry.--expand-hover::after {
  width: 100%;
}

/* #endregion */

/* #endregion */