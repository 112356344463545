.accordion__title-element-wrapper.boxes-accordion__title-element-wrapper {
  display: grid;

  gap: 2rem;
  grid-template-columns: 4fr 2fr;
}

.box-title__text {
  grid-column: 1 / 2;
  padding-left: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box-title__buttons {
  grid-column: 2 / 3;

  width: 100%;

  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: var(--dark1);
  border-radius: 1rem;
  box-shadow: var(--control-shadow);

  padding-left: 1rem;
  padding-right: 1rem;
  text-align: right;

  z-index: 1;
}
.box-title__buttons > * + * {
  margin-left: clamp(10px, 1rem, 5vw);
}