.left-bg-panel {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  
  min-height: 768px;
}
header {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}
main {
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--dark2);
}
.right-bg-panel {
  grid-row: 1 / 3;
  grid-column: 3 / 4;

  min-height: 768px;
}

.app {
  position: relative;
  width: 100%;
  min-width: fit-content;
  height: 100%;
  min-height: fit-content;

  background-color: rgba(var(--dark3-components), 0.5);
  z-index: 1;
  padding-bottom: 0.5rem;
}

.main-content__wrapper {
  position: relative;
  width: 100%;
  min-width: fit-content;
  height: calc(100% - var(--nav-bar-height) + 0.5rem);
  min-height: fit-content;

  padding: 0.5rem;
}

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color:#000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 15px;
  border:4px solid var(--blue1);
  text-align:center;
  font-size:1.2em;
  z-index:999;
}

/* Home pages */
/* #region */

.section-home__wrapper {
  display: flex;
  justify-content: center;

  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  font-size: 1.2rem;
  text-align: justify;
  background-color: rgba(var(--dark3-components), 0.5);
  padding: 0.5em 1.5em 2em 1em;

  transition: opacity 1.5s ease;
}

.section-home__padder {
  position: relative;
  height: fit-content;

  max-width: 60ch;
  line-height: 1.6;
  font-family: var(--sans-serif);
}

.section-home__section:not(:nth-of-type(1)) {
  margin-top: 1rem;
}

/* Width for text */
.section-home__text,
.section-home__list {
  max-width: 60ch;
}

/* Indentation */
.section-home__section {
  position: relative;
}
.section-home__text {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-left: 0.5em;
}
.section-home__list {
  position: relative;

  margin: 1em;
  font-size: 0.9em;
  padding: 0.5em 1em 0.5em 1em;
}
/* Style bullet points */
.section-home__list-item::before {
  content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  margin-right: 0.5em;
}

.section-home__padder h1 {
  margin-left: -0.5em;
  font-weight: 900;
}

.section-home__padder h2 {
  font-weight: 700;
}

/* Colors based on section */

/* For parts of the app which are currently unusable (e.g. Cart with no saved boxes), there will be a note instructing the user on what to do in order to use the part. This note will contain the names of sections and widgets, which we'll highlight as we do in the home pages. */
.analyzer__unusable-element-note,
.builder__unusable-element-note,
.planner__unusable-element-note {
  padding-block: 1rem;
  padding-inline: 1ch;
}

.analyzer-home h1,
.analyzer-home h2,
.analyzer__unusable-element-note .app-element-name.--section,
.analyzer-home .app-element-name.--section {
  color: var(--red-bg);
}
.analyzer__unusable-element-note .app-element-name.--widget,
.analyzer-home .app-element-name.--widget {
  color: var(--red1);
}
.analyzer-home .section-home__list {
  border: 1px solid rgba(var(--red-bg-components), 0.4);
  box-shadow: 6px 6px 10px rgba(var(--red-bg-components), 0.2);
}
.analyzer-home .section-home__list-item::before {
  border-top: 0.25em solid transparent;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid var(--red-bg);
}

.builder-home h1,
.builder-home h2,
.builder__unusable-element-note .app-element-name.--section,
.builder-home .app-element-name.--section {
  color: var(--green-bg);
}
.builder__unusable-element-note .app-element-name.--widget,
.builder-home .app-element-name.--widget {
  color: var(--green1);
}
.builder-home .section-home__list {
  border: 1px solid rgba(var(--green-bg-components), 0.4);
  box-shadow: 6px 6px 10px rgba(var(--green-bg-components), 0.2);
}
.builder-home .section-home__list-item::before {
  border-top: 0.25em solid transparent;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid var(--green-bg);
}

.planner-home h1,
.planner-home h2,
.planner__unusable-element-note .app-element-name.--section,
.planner-home .app-element-name.--section {
  color: var(--blue-bg);
}
.planner__unusable-element-note .app-element-name.--widget,
.planner-home .app-element-name.--widget {
  color: var(--blue1);
}
.planner-home .section-home__list {
  border: 1px solid rgba(var(--blue-bg-components), 0.4);
  box-shadow: 6px 6px 10px rgba(var(--blue-bg-components), 0.2);
}
.planner-home .section-home__list-item::before {
  border-top: 0.25em solid transparent;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid var(--blue-bg);
}

/* Styling for spans on home pages */
/* #region */

.app-element-name.--section {
  font-weight: 500;
}
.app-element-name.--widget {
  font-family: var(--monospace);
}

.--key {
  border: 1px solid var(--light3);
  padding: 0.1rem 0.25rem 0.1rem 0.25rem;
  font-size: 1rem;
  font-family: var(--monospace);
  font-weight: 800;
  border-radius: 5px;
  min-width: 2ch;
  background-color: var(--light2);
  color: var(--dark3);
  box-shadow: inset -2px -2px rgba(0, 0, 0, 0.2);
}

/* #endregion */

/* #endregion */

/* BG Panels */
/* #region */
.left-bg-panel,
.right-bg-panel {
  width: 100%;
  height: 100vh;
  position: relative;
}
.left-bg-panel {
  grid-column: 1 / 2;
}
.right-bg-panel {
  grid-column: 3 / 4;
}

.left-bg-panel::after, .right-bg-panel::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.left-bg-panel::after {
  background: linear-gradient(
    to left,
    rgba(var(--dark2-components), 0),
    rgba(var(--dark2-components), 0.8),
    rgba(var(--dark3-components), 1),
    rgba(var(--dark3-components), 1)
  );
  transition: left var(--bg-transition), opacity var(--bg-transition), width var(--bg-transition);
}
/* Hide gradient on --none */
.--none.left-bg-panel::after {
  background: linear-gradient(
    to left,
    rgba(var(--dark3-components), 0),
    rgba(var(--dark3-components), 0.8),
    rgba(var(--dark3-components), 1),
    rgba(var(--dark3-components), 1)
  );
  transition: left var(--bg-transition), opacity var(--bg-transition), width var(--bg-transition);
}
.left-bg-panel.pulse::after {
  left: -5px;
  width: calc(100% - 5px);
  opacity: 0.95;
}
.right-bg-panel::after {
  background: linear-gradient(
    to right,
    rgba(var(--dark2-components), 0),
    rgba(var(--dark2-components), 0.8),
    rgba(var(--dark3-components), 1),
    rgba(var(--dark3-components), 1)
  );
  transition: left var(--bg-transition), opacity var(--bg-transition), width var(--bg-transition);
}
/* Hide gradient on --none */
.--none.right-bg-panel::after {
  background: linear-gradient(
    to right,
    rgba(var(--dark3-components), 0),
    rgba(var(--dark3-components), 0.8),
    rgba(var(--dark3-components), 1),
    rgba(var(--dark3-components), 1)
  );
  transition: left var(--bg-transition), opacity var(--bg-transition), width var(--bg-transition);
}
.right-bg-panel.pulse::after {
  left: 5px;
  width: calc(100% - 5px);
  opacity: 0.95;
}

/* #endregion */

/* Background managing */
/* #region */

/* Background color */

.bg.--none,
.bg.--red,
.bg.--green,
.bg.--blue {
  -webkit-transition: background-color var(--bg-transition);
  -ms-transition: background-color var(--bg-transition);
  transition: background-color var(--bg-transition);
}
.bg.--none {
  background-color: var(--dark3);
}
.bg.--red {
  background-color: var(--red-bg);
}
.bg.--green {
  background-color: var(--green-bg);
}
.bg.--blue {
  background-color: var(--blue-bg);
}

.bg.--none.bg-shadow.--none,
.bg.--red.bg-shadow.--red,
.bg.--green.bg-shadow.--green,
.bg.--blue.bg-shadow.--blue {
  -webkit-transition: background-color var(--bg-transition), box-shadow var(--bg-transition);
  -ms-transition: background-color var(--bg-transition), box-shadow var(--bg-transition);
  transition: background-color var(--bg-transition), box-shadow var(--bg-transition);
}

/* Box shadow along bottom and right based on background color */
.bg-shadow.--none,
.bg-shadow.--red,
.bg-shadow.--green,
.bg-shadow.--blue {
  transition: box-shadow var(--bg-transition);
}
.bg-shadow.--none {
  box-shadow: var(--bg-shadow-none);
}
.bg-shadow.--none.pulse {
  box-shadow: var(--bg-shadow-none-pulse);
}
.bg-shadow.--red {
  box-shadow: var(--bg-shadow-red);
}
.bg-shadow.--red.pulse {
  box-shadow: var(--bg-shadow-red-pulse);
}
.bg-shadow.--green {
  box-shadow: var(--bg-shadow-green);
}
.bg-shadow.--green.pulse {
  box-shadow: var(--bg-shadow-green-pulse);
}
.bg-shadow.--blue {
  box-shadow: var(--bg-shadow-blue);
}
.bg-shadow.--blue.pulse {
  box-shadow: var(--bg-shadow-blue-pulse);
}

/* Box shadow along up and left based on background color */
.bg-control.--red {
  box-shadow: var(--bg-control-red);
}
.bg-control.--green {
  box-shadow: var(--bg-control-green);
}
.bg-control.--blue {
  box-shadow: var(--bg-control-blue);
}

/* #endregion */