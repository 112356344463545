.move-select__wrapper {
  display: flex;
  flex-direction: column;

  position: relative;
  width: minmax(max-content, 100%);
  height: 100%;
}

.move-select__entries-wrapper {
  position: relative;

  height: 100%;
  width: 100%;
}
.move-select__entries-wrapper > * + * {
  margin-top: 0.5rem;
}

.move-select__entry,
.move-select__legend {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(15ch, min-content) minmax(4ch, 36px) 4ch 4ch 4ch 7ch;

  align-items: center;
  gap: 0 30px;

  position: relative;
  min-width: fit-content;
  text-align: left;
  width: 100%;

  padding: 0.5rem 1rem 0.5rem 0.5rem;

}

.move-select__legend {
  grid-template-rows: 24px;

  position: relative;

  background-color: var(--dark2);
  box-shadow: 0px 1px var(--dark1);
  margin-bottom: 1rem;
  font-weight: 600;
}
.move-select__legend > div {
  position: relative;
  display: flex;
  align-items: flex-end;
}
.move-select__legend > div > span:hover {
  cursor: pointer;
}
.move-select__legend > .move-select__name {
  margin-left: 4ch;
}

.move-select__entry {
  position: relative;

  background-color: var(--dark2);
  box-shadow: 0px 1px var(--dark1);

  transition: 0.2s ease;
}
.move-select__entry:hover {
  cursor: pointer;
  filter:brightness(1.1);
}

.move-select__entry.--event::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(var(--red1-components), 0.1);
}