.versus__wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: row;
  gap: 0.5rem;

  position: relative;
  width: 100%;
  height: 100%;

  font-family: var(--monospace);
}

.versus__user-team-cell {
  grid-row: 1 / 7;
  grid-column: 1 / 2;
}

.versus__enemy-team-cell {
  grid-row: 1 / 7;
  grid-column: 3 / 4;
}

.versus__matchup-cell {
  grid-row: 1 / 7;
  grid-column: 2 / 3;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5rem;

  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0.5rem 0;
}

.versus__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.versus-matchup__table-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}