html {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}

/* Variable declarations */
:root {
  --app-width: clamp(1024px, 90vw, 1440px);

  font-family: var(--monospace);
  overflow-x: hidden;
  line-height: 1.5;
  font-size: 16px;

  /* Darks */
  --dark1-components: 84, 84, 84;
  --dark1: rgb(var(--dark1-components));
  --dark2-components: 52, 52, 52;
  --dark2: rgb(var(--dark2-components));
  --dark3-components: 20, 20, 20;
  --dark3: rgb(var(--dark3-components));
  
  
  /* Lights */ 
  --light1-components: 237, 237, 237;
  --light1: rgb(var(--light1-components));
  --light2-components: 222, 222, 222;
  --light2: rgb(var(--light2-components));
  --light3-components: 140, 140, 140;
  --light3: rgb(var(--light3-components));
  
  /* Blues */
  --blue-bg-components: 204, 204, 255;
  --blue-bg: rgb(var(--blue-bg-components));

  --blue1-components: 173, 216, 230;
  --blue1: rgb(var(--blue1-components));
  --blue2-components: 103, 191, 219;
  --blue2: rgb(var(--blue2-components));
  --blue3: #515158;

  /* Greens */
  --green-bg-components: 161, 201, 161;
  --green-bg: rgb(var(--green-bg-components));

  --green1-components: 100, 220, 140;
  --green1: rgb(var(--green1-components));
  --green2-components: 62, 176, 100;
  --green2: rgb(var(--green1-components));
  --green3: #515851;

  /* Reds */
  --red-bg-components: 240, 128, 128;
  --red-bg: rgb(var(--red-bg-components));

  --red1-components: 229, 50, 66;
  --red1: rgb(var(--red1-components));
  --red2-components: 220, 20, 38;
  --red2: rgb(var(--red1-components));
  --red3: #585151;

  /* Fonts */
  --monospace: monospace;
  --sans-serif: Verdana, Geneva, Tahoma, sans-serif;

  /* Shadows */
  --bg-shadow-gap: 1px;
  --cell-padding: clamp(3px, 0.6rem, max(2vw, 3vh));

  --button-shadow: inset 0px -5px 1px 0 rgba(0, 0, 0, 0.2);

  /* Shadow along control */

  --control-shadow-inset: 3px 3px;
  --control-shadow-inset-pulse: 3px 3px 1px 1px;
  --control-shadow: inset var(--control-shadow-inset) rgba(0,0,0,0.2);

  --bg-control-none: inset var(--control-shadow-inset) rgba(0,0,0,0.2);
  --bg-control-red: inset 2px 2px 2px 1px rgba(var(--red-bg-components), 0.5);
  --bg-control-green: inset 2px 2px 2px 1px rgba(var(--green-bg-components), 0.5);
  --bg-control-blue: inset 2px 2px 2px 1px rgba(var(--blue-bg-components), 0.5);


  /* Shadow along background pulsing */
  /* #region */

  --bg-shadow-inset: -1px -1px 3px 1px;
  --bg-shadow-inset-pulse: -1px -1px 3px 2px;
  
  --bg-shadow-none: inset var(--bg-shadow-inset) var(--dark3);
  --bg-shadow-none-pulse: inset var(--bg-shadow-inset-pulse) var(--dark3);
  
  --bg-shadow-red: inset var(--bg-shadow-inset) rgba(var(--red-bg-components), 0.8);
  --bg-shadow-red-pulse: inset var(--bg-shadow-inset-pulse) rgba(var(--red-bg-components), 1);

  --bg-shadow-green: inset var(--bg-shadow-inset) rgba(var(--green-bg-components), 0.8);
  --bg-shadow-green-pulse: inset var(--bg-shadow-inset-pulse) rgba(var(--green-bg-components), 1);

  --bg-shadow-blue: inset var(--bg-shadow-inset) rgba(var(--blue-bg-components), 0.8);
  --bg-shadow-blue-pulse: inset var(--bg-shadow-inset-pulse) rgba(var(--blue-bg-components), 1);

  /* #endregion */

  /* Transitions */
  --transition-duration: 1s;
  --bg-transition: var(--transition-duration) ease;

  /* Heights */
  --nav-bar-height: calc(1.5rem + 2 * var(--cell-padding));
  --control-panel-height: calc(7rem + 4 * var(--cell-padding));
  --header-height: calc(var(--nav-bar-height) + var(--control-panel-height));
}

body {
  margin: 0;
}

#root {
  display: grid;
  grid-template-rows: var(--header-height) calc(max(100vh, 768px) - var(--header-height));
  grid-template-columns: 1fr var(--app-width) 1fr;

  position: relative;
  width: 100vw;
  min-height: 100vh;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Main font color */
  color: var(--light1);
}

/* Visually hide headers and labels that are solely meant for screen readers */
.hidden-header,
.hidden-label {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

/* Remove fieldset styling so that stylistically, it's a <div> */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* Overwrite old link styles */
a, a:visited, a:hover, a:active {
  color: inherit;
}

/* Remove default button styling */
button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}

/* Remove default text input styling */
input[text] {
  background: none;
  border: none;
  outline: none;
}

input:focus {
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 0;
  outline: none;
}

/* Remove default textarea styling */
textarea {
  outline: none;
  resize: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-family: inherit;
  font-weight: inherit;
}
h1 {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  font-size: 1.5em;
}
h2 {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  font-size: 1.25em;
}
h3 {
  margin-block-start: 0.125em;
  margin-block-end: 0.125em;
  font-size: 1.125em;
}
h4 {
  margin-block-start: 0.125em;
  margin-block-end: 0.125em;
  font-size: 1em;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

/* Remove default list styles */
ol, ul, dl, menu {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}
li, dd, dt {
  list-style: none;
  margin-inline-start: 0;
}

/* Remove default checkbox/radio styling */
input[type="checkbox"], input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Scrollbars */
/* #region */

/* Firefox */
/* #region */

* {
  scrollbar-width: thin;
  scrollbar-color: var(--light1) none;
}

/* #endregion */

/* Chrome, Edge, Safari */
/* #region */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--light3);
}

::-webkit-scrollbar-thumb {
  background: var(--light1);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--light2);
}

/* #endregion */

/* #endregion */

/* font size change media query */
@media screen and (max-width: 1440px) {
  :root {
    font-size: 15px;
  }
}
@media screen and (max-width: 1150px) {
  :root {
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {
  :root {
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  #root {
    overflow-x: scroll;
  }
}
@media screen and (max-height: 768px) {
  #root {
    overflow-y: scroll;
  }
}