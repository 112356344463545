/* nav-bar */
/* #region */

.nav-bar__wrapper {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: var(--nav-bar-height);

  background-color: var(--dark2);
  padding: var(--cell-padding);
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.nav-bar {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0.5rem;

  width: 100%;

  background-color: var(--dark1);
  box-shadow: var(--control-shadow);
}

.nav-item {
  list-style: none;
}

.nav-item a:focus {
  outline: none;
}

.nav-item a:focus .button::after {
  content: '';

  top: 0;
  left: -10%;

  position: absolute;
  width: 120%;
  height: 100%;

  border: 1px dashed var(--blue1);
}
.nav-item a:focus .button.--active::after {
  content: '';

  top: 0;
  left: calc(-10% - 8px);

  position: absolute;
  width: calc(120% + 8px);
  height: 100%;

  border: 1px dashed var(--blue1);
}

/* #endregion */