.speed-control__wrapper {
  min-width: calc(13ch);
}

.speed-control__entry {
  display: grid;
  grid-template-columns: 9ch 3ch;
  gap: 1ch;
  justify-content: center;
  align-items: center;

  position: relative;
  width: auto;
  height: clamp(1.5rem, 2rem, 5%);
}

.speed-control__name,
.speed-control__value {
  grid-column: span 1;
  align-self: center;

  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
}
/* Pad cells */
.speed-control__name,
.speed-control__value {
  padding: 0 1ch 0 1ch;
}
/* Center cells except names */
.speed-control__value {
  justify-content: center;
}

/* Background colors */

.speed-control__entry:nth-of-type(2n) {
  background-color: rgba(var(--dark1-components), 0.5);
}
.speed-control__entry:nth-of-type(2n + 1) {
  background-color: rgba(var(--dark2-components), 0.5);
}
.speed-control__value:hover,
.speed-control__entry:not(:first-of-type) > td:not(.speed-control__name):focus-within {
  outline: 2px dashed var(--blue1);
}