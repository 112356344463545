.type-matchup__table-body {
  min-width: calc(40px + 51ch);
  min-height: 420px;
}

.type-matchup__entry {
  display: grid;
  grid-template-columns: 40px repeat(10, 3ch);
  gap: 1.5ch;
  justify-content: center;
  align-items: center;

  position: relative;
  width: auto;

  text-align: center;
}
.type-matchup__entry:not(:first-of-type) {
  color: var(--light2);
}

.type-matchup__icon,
.type-matchup__0,
.type-matchup__1-4,
.type-matchup__1-2,
.type-matchup__1,
.type-matchup__2,
.type-matchup__4 {
  grid-column: span 1;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
}

/* Background colors */

.type-matchup__entry:nth-of-type(2n) {
  background-color: rgba(var(--dark1-components), 0.5);
}
.type-matchup__entry:nth-of-type(2n + 1) {
  background-color: rgba(var(--dark2-components), 0.5);
}
.type-matchup__entry > td:nth-of-type(2n):not(.type-matchup__icon),
.type-matchup__entry > th:nth-of-type(2n):not(.type-matchup__icon) {
  background-color: rgba(var(--dark1-components), 0.1);
}

.type-matchup__entry > td:nth-of-type(2n + 1):not(.type-matchup__icon),
.type-matchup__entry > th:nth-of-type(2n + 1):not(.type-matchup__icon) {
  background-color: rgba(var(--dark1-components), 0.3);
}

.type-matchup__entry:not(:first-of-type) > td:not(.type-matchup__icon):hover,
.type-matchup__entry:not(:first-of-type) > td:not(.type-matchup__icon):focus-within,
.type-matchup__entry:not(:first-of-type) > th:not(.type-matchup__icon):hover
.type-matchup__entry:not(:first-of-type) > th:not(.type-matchup__icon):focus-within {
  outline: 2px dashed var(--blue1);
}
