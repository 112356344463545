.export__textbox-wrapper {
  display: flex;
  flex-direction: column;

  position: relative;
  width: 40ch;
  height: 25rem;
  overflow-y: scroll;

  background-color: var(--dark3);
  box-shadow: var(--control-shadow);
  border-radius: 0.25rem;
  padding: 1rem;
  line-height: 1;
  font-family: var(--monospace);
}

.export__member-name {
  font-weight: 600;
}
.export__member:nth-child(odd) .export__member-name {
  color: var(--blue1);
}
.export__member:nth-child(even) .export__member-name {
  color: var(--green1);
}