.import__textbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: relative;

  background-color: rgba(var(--dark3-components), 0.7);
  box-shadow: var(--control-shadow);
  border-radius: 0.25rem;
  padding: 1rem;
}

.import__input {
  border: 1px solid var(--light3);
  background-color: var(--dark3);
  color: var(--light1);
  padding: 1ch;

  transition: 0.5s ease;
}
.import__input:hover,
.import__input:focus {
  border: 1px solid var(--blue1);
  box-shadow: inset 0 0 2px 1px var(--blue1);
  filter: brightness(1.1);
}

.import__bottom-wrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-top: 0.5rem;
}

.import__message {
  justify-self: flex-start;
  color: var(--red1);
}
.import__button {
  justify-self: flex-end;

  background-color: var(--dark2);
  box-shadow: var(--control-shadow);
  padding: 0 1rem 0 1rem;
  border-radius: 0.5rem;

  height: fit-content;
}