.team-view-accordion__wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  
  justify-self: center;
  align-self: center;
  padding: 0.25rem;
}

.accordion__title-element-wrapper.pinned-boxes-accordion__title-element-wrapper {
  display: grid;
  grid-template-columns: 1fr 9ch;
}

.pinned-box-title__text {
  grid-column: 1 / 2;
  padding-left: 0.5rem;
  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 1rem;
}

.pinned-box-title__buttons {
  grid-column: 2 / 3;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--dark1);
  border-radius: 1rem;
  box-shadow: var(--control-shadow);

  padding-left: 1rem;
  padding-right: 1rem;
  text-align: right;

  z-index: 1;
}
.pinned-box-title__buttons > * + * {
  margin-left: clamp(10px, 1rem, 5vw);
}

/* Icons */
/* #region */

.pinned-box__pokemon-icon-container {
  position: relative;
  right: -0.25rem;
}

.pinned-box__pokemon-icon-track {
  position: absolute;
  width: 60px;
  height: 10px;
  margin-top: 22px;
  right: -10px;
  background-color: var(--light1);
  border-radius: 5px;
  opacity: 0.5;
}

.pinned-box__pokemon-icon-background {
  /* Centering  */
  margin-left: 10px;
  position: absolute;
}
.pinned-box__pokemon-icon-container:focus-within > .pinned-box__pokemon-icon-background,
.pinned-box__pokemon-icon-container:hover > .pinned-box__pokemon-icon-background {
  background-color: var(--blue1);
}

.pinned-box__pokemon-icon {
  /* Centering  */
  margin-left: 10px;
  position: absolute;
  background-image: url('../../../../../../assets/icons/pokemonicons-sheet.png');
  background-repeat: no-repeat;
}

.pinned-box__pokemon-icon:hover,
.pinned-box__pokemon-icon:focus {
  filter: brightness(1.1);
  transform: scale(1.2);
  cursor: pointer;
  transition: transform 0.1s;
}

/* #endregion */