/* Entity pages */
/* #region */

.planner-page__wrapper {
  position: relative;
  height: 100%;

  padding-bottom: 1rem;
  font-family: var(--monospace);
}

.planner-page__accordion-wrapper {
  position: relative;
  /* Full height of page, minus h1 height */
  height: calc(100% - 2rem);
  overflow-y: scroll;
  background-color: rgba(var(--dark3-components), 0.5);
}

.planner-page__accordion-wrapper > .accordion {
  position: relative;
  
  border-radius: 0;
  padding-left: 1rem;
  padding-right: 1rem;

  --accordion-left-shadow-size: 0.25rem;
  font-family: var(--monospace);
}


/* Descriptions */
/* #region */

.planner-page__descriptions-table {
  border: 1px solid white;
  grid-column: 1 / 13;
}

.planner-page__description {
  border: 1px solid white;
  grid-column: 1 / 13;
}

.planner-page__descriptions-table:nth-child(even) {
  background-color: lightgray;
}

.planner-page__description.--main-entity {
  grid-column: 1 / 13;
  border: 1px solid white;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3fr;

  width: 33.3%;
}

.planner-page__description-code {
  border: 1px solid white;
  
  grid-column: 1 / 2;
}

.planner-page__description-text {
  border: 1px solid white;

  grid-column: 2 / 3;
}

/* #endregion */

/* Accordion titles */
/* #region */

.planner-accordion__title {
  font-family: var(--monospace);
  font-weight: 500;
}

/* #endregion */