/* Pokemon icons */
/* #region */

/* Controls */
/* #region */

.planner-search__entry-icons, .planner-accordion__entry-icons {
  --entry-icons-right-margin: 1rem;
}
/* Shrinks height gap caused by <br /> tag so that having one row of Pokemon icons does not cause an expand to be necessary; without this, having any Pokemon icons at all will always necessitate expansion of the entry to see everything */
.planner-search__entry-icons:not(.planner__control-buttons) {
  line-height: 1.0;
}

.planner__control-buttons {
  width: auto;
  margin-right: var(--entry-icons-right-margin);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  position: relative;
  height: 2rem;

  background-color: var(--dark1);
  border-radius: 1rem;

  box-shadow: var(--control-shadow);

  padding-right: 1rem;
  text-align: right;
  /* Preserves button height */
  line-height: 1.5;
}

.planner__control-buttons > * + * {
  margin-left: 1rem;
}

/* #endregion */

/* Icons */
/* #region */

.planner__pokemon-icons-background {
  position: relative;
  margin-right: var(--entry-icons-right-margin);
  border-radius: 1rem;

  background-color: var(--dark1);
  /* Height of .planner-control__buttons */
  min-height: 2rem;

  /* This keeps the icon track from overflowing .--note that each element of the track has a larger width than its container. Due to the track's absolute positioning, it will ignore the padding that already exists for the pokemon-icons-background element in its parent entry. */
  padding-right: 20px;
  padding-left: 5px;
  padding-block: 0.25rem;

  box-shadow: var(--control-shadow);
}

.planner__pokemon-icons-reason {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

.planner__pokemon-icon-container {
  position: relative;
  right: -0.25rem;
}

.planner__pokemon-icon-track {
  position: absolute;
  width: 60px;
  height: 10px;
  margin-top: 22px;
  right: -10px;
  background-color: var(--light1);
  border-radius: 5px;
  opacity: 0.5;
}

.planner__pokemon-icon-background {
  /* Centering  */
  margin-left: 10px;
  position: absolute;
}

.planner__pokemon-icon {
  /* Centering  */
  margin-left: 10px;
  position: absolute;
  background-image: url('../../../../assets/icons/pokemonicons-sheet.png');
  background-repeat: no-repeat;
}

.planner__pokemon-icon:hover {
  filter: brightness(1.1);
  transform: scale(1.2);
  cursor: pointer;
  transition: transform 0.1s;
}

.planner-accordion__entry .planner__pokemon-icons-background {
  background-color: var(--dark1);
}

/* #endregion */

/* #endregion */

/* Link icons cons */
/* #region */

.planner__icon-container {
  position: relative;
  margin-left: var(--entry-horizontal-padding);
}

.planner__icon-background {
  position: absolute;
}

/* #endregion */