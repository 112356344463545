/* Slider base */
/* #region */

.slider__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range {
  top: -0.15rem;
  position: absolute;

  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
}

.slider__range {
  background-color: #9fe5e1;
}

/* Remove default appearance */
.slider__thumb,
.slider__thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.slider__thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: inherit;
  outline: none;
  z-index: 3;
}

.slider__thumb:hover,
.slider__thumb:focus {
  filter: brightness(1.1);
}

/* For Chrome browsers */
.slider__thumb::-webkit-slider-thumb {
  background-color: var(--blue1);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px white;
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin-top: 6px;
  top: -0.15rem;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.slider__thumb::-moz-range-thumb {
  background-color: var(--blue1);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px white;
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin-top: 6px;
  top: -0.15rem;
  pointer-events: all;
  position: relative;
}

/* #endregion */

/* Slider */
/* #region */

.slider__wrapper {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  display: flex;

  width: 100%;
  height: 30px;

  justify-content: center;
  align-items: center;
}

/* #endregion */