.coverage-view__wrapper {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: row;
  gap: 1rem;

  position: relative;
  width: 100%;
  height: 100%;

  font-family: var(--monospace);
}

/* Cell formatting */
/* #region */ 

.team-column-cell,
.type-matchup__cell,
.field-control__cell,
.speed-control__cell,
.status-control__cell {
  position: relative;
  width: 100%;
  height: 100%;
}

.team-column__cell {
  grid-row: 1 / 7;
  grid-column: 1 / 2;
}

.type-matchup__cell {
  grid-row: 1 / 7;
  grid-column: 2 / 3;

  display: flex;
  justify-content: center;
}

.status-control__cell {
  grid-row: auto;
  grid-column: 3 / 4;
}

.coverage-view__spacer {
  grid-column: 3 / 4;
}

.field-control__cell {
  grid-row: auto;
  grid-column: auto;
}

.speed-control__cell {
  grid-row: auto;
  grid-column: auto;
}

/* #endregion */

.coverage-view__wrapper button {
  outline: none;
}

.type-matchup__table-body,
.status-control__wrapper,
.speed-control__wrapper,
.field-control__wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  gap: 0;

  position: relative;
  width: fit-content;
  min-width: calc(40px + 51ch);
  height: auto;

  padding: 0.5rem;
  background-color: rgba(var(--dark3-components), 0.5);
  box-shadow: 3px 3px 3px 0 rgba(var(--dark3-components), 0.9);
  border: 1px solid var(--light3);
}