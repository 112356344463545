.reference-panel__wrapper {
  padding: var(--team-builder-gap);
  background-color: var(--dark3);
}

.reference-panel__padder {
  position: relative;
  width: 100%;
  height: 100%;
  
  border: var(--team-builder-border3);
  padding: calc(1rem + 6px) 1rem 1rem calc(1rem + 6px);

  transition: border 0.3s ease;
}
.reference-panel__padder:hover,
.reference-panel__padder:focus-within {
  border: var(--team-builder-border1);
}

.reference-panel__content {
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;

  height: 100%;
  width: 100%;

  /* Matches scroll bars */
  box-shadow: 
  -6px 0 0 0 var(--light3),
  0 -6px 0 0 var(--light3),
  -6px -6px 0 0 var(--light1);
}

/* Entity selections */
/* #region */

.ability-select__entry:first-of-type,
.item-select__entry:first-of-type,
.move-select__entry:first-of-type,
.nature-select__entry:first-of-type,
.hp-select__entry:first-of-type {
  background-color: var(--dark1);
  border-top: 1px solid var(--light3);
  border-bottom: 1px solid var(--light3);
  box-shadow: none;
}

.ability-select__wrapper fieldset,
.item-select__wrapper fieldset,
.move-select__wrapper fieldset,
.nature-select__wrapper fieldset,
.hp-select__wrapper fieldset {
  margin-top: 0.5rem;
  width: 100%;
}

.ability-select__results,
.item-select__results,
.move-select__results,
.nature-select__results,
.hp-select__results {
  margin-top: 0.5rem;

  position: relative;
  width: 100%;
  height: 100%;

  font-size: 12px;
}

/* #endregion */