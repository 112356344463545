.gen-filter__wrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3fr 1fr;

  justify-content: center;
  align-items: center;
}

.gen-filter__label {
  grid-row: 1 / 2;
  grid-column: 1 / 2;

  justify-self: center;
  align-self: center;

  font-family: var(--monospace);
  font-size: 1rem;
  margin-right: 0.5rem;
}

.gen-filter__slider {
  grid-row: 1 / 2;
  grid-column: 2 / 3;

  display: flex;
  justify-content: center;
  align-items: center;
}

.gen-filter__buttons {
  grid-row: 1 / 2;
  grid-column: 3 / 4;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  height: 3rem;
}