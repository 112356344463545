/* Spacing between search entries, and shape */
.planner-search__results > * + * {
  margin-top: var(--entry-top-margin);
}

.planner-search__entry {
  padding-top: var(--entry-vertical-padding);
  box-shadow: 0 1px 1px 0px rgba(var(--dark1-components), 1);
}

/* Lower shadow */
/* #region */

.planner-search__entry::after {
  content: '';
  display: block;
  position: absolute;
  box-shadow: inset var(--bg-shadow-inset) rgba(var(--blue1-components), 1);
  bottom: 0;
  left: 0;
  width: 0%;
  height: 3px;
  transition: 0.5s ease;
}

.planner-search__entry.--expand-hover::after {
  width: 100%;
}

/* #endregion */