.member-details__extra-wrapper {
  grid-row: 2 / 3;
  grid-column: 1 / 2;

  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: 100%;
  gap: var(--team-builder-gap);
  
  width: 100%;
  height: 100%;
}

.member-details__nickname {
  border: var(--team-builder-border3);
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  
  position: relative;
}

.member-details__level {
  grid-row: 2 / 3;

  position: relative;

  border: var(--team-builder-border3);
}

.member-details__gender {
  grid-row: 3 / 4;

  position: relative;

  border: var(--team-builder-border3);
}

.member-details__shiny {
  grid-row: 4 / 5;

  position: relative;

  border: var(--team-builder-border3);
}

.member-details__happiness {
  grid-row: 5 / 6;
  
  position: relative;

  border: var(--team-builder-border3);

  font-size: 0.8rem;
}