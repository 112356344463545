.versus-controls__wrapper > form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  font-weight: 500;

  background-color: rgb(var(--dark3-components), 0.6);
  border: 1px solid var(--light3);
  box-shadow: 2px 2px 2px 1px black;
}

/* 6ch since 'IMPORT' has 6 letters. 2px for the border from the popup. */
.versus-controls__clear {
  width: calc(6ch + 2px);
}

.versus-controls__mode-toggle:hover,
.versus-controls__mode-toggle:focus,
.versus-controls__clear:hover,
.versus-controls__clear:focus {
  text-decoration: underline;
  cursor: pointer;
}

.versus-controls__field,
.versus-controls__side {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  height: auto;

  background-color: var(--dark3);
  padding: 1rem;
}

.versus-controls__field > h3,
.versus-controls__side > h3 {
  color: var(--blue1);
}

.versus-controls__field > * + *,
.versus-controls__side > * + * {
  margin-top: 0.5rem;
}

.versus-controls__weather,
.versus-controls__terrain,
.versus-controls__side-toggles {
  display: grid;
  gap: 1ch 2ch;
  justify-items: flex-start;
}
.versus-controls__weather {
  grid-template-columns: repeat(3, 6ch);
}
.versus-controls__terrain {
  grid-template-columns: repeat(2, 8ch);
}
.versus-controls__side-toggles {
  grid-template-columns: repeat(2, 12ch);
}

.versus-controls__wrapper > button {
  outline: none;
}