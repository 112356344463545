.member-details__main-wrapper {
  grid-row: 1 / 3;
  grid-column: 2 / 3;

  display: grid;
  grid-template-rows: 45px clamp(7rem, 12vh, 9rem) 1fr;
  grid-template-columns: 1fr 1fr;
  gap: var(--team-builder-gap);

  width: 100%;
  height: 100%;
}

.member-details__ability,
.member-details__item,
.member-details__moveset {
  font-size: 14px;
}

/* Moveset */
/* #region */

.member-details__moveset {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  
  position: relative;
  
  padding: calc(0.5 * var(--team-builder-gap));
  transition: 0.3s ease;
}

.member-details__moveset-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: var(--team-builder-gap);

  position: relative;
  width: 100%;
  height: 100%;
}

.member-details__move-wrapper {
  display: grid;
  grid-template-columns: 45px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  gap: 0.5rem;

  position: relative;
  width: 100%;
  height: 100%;

  transition: 0.3s ease;
}

.member-details__move-type {
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-details__move-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.member-details__move-event {
  color: var(--red1);
  font-weight: 600;
}

/* #endregion */

.member-details__ability {
  border: var(--team-builder-border3);
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  
  position: relative;
}

/* Item */
/* #region */ 

.member-details__item {
  border: var(--team-builder-border3);
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  
  position: relative;
}

.member-details__item > .member-details__content {
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 50px 1fr;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  
  position: relative;
  width: 100%;
  height: 100%;
}

.member-details__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 50px;
  height: 26px;
  
  background-color: var(--dark2);
  box-shadow: var(--control-shadow);
  border-radius: 50%;
}

.member-details__item-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #endregion */

/* #endregion */
