.team-display__wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
  justify-self: center;
  align-self: center;
}

.team-display__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 40px;
  height: 30px;
  justify-self: center;
  align-self: center;

  border-radius: 50%;
  background-color: var(--dark2);
  box-shadow:
    var(--control-shadow), 
    0 0 1px 1px var(--light3);
  transition: box-shadow 0.5s ease;
}

.team-display__icon.--occupied {
  box-shadow:
    var(--control-shadow),
    0 0 3px 2px var(--blue1);
}