/* ControlPanel */

.control-panel__wrapper {
  min-width: fit-content;
  width: 100%;
  height: var(--control-panel-height);
  
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 4fr;
  grid-template-rows: 50% 50%;

  /* Gap between entries so shadow shows through */
  transition: var(--bg-transition);
  transition-property: border;

  /* Makes dropdowns originating in this div go over other content, e.g. Pokemon icons */
  /* z-index: 10; */
}

/* Arrangement */
/* #region */

.gen-filter__cell {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.tier-filter__cell {
  grid-row: 1 / 2;
  grid-column: 2 / 4;
}

.type-filter__cell {
  grid-row: 1 / 2;
  grid-column: 4 / 5;
}

.stat-filter__cell {
  grid-row: 2 / 3;
  grid-column: 4 / 5;
}

.team-display__cell {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.import-export__cell {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}

/* #endregion */

/* Border effects */
/* #region */

.gen-filter__cell,
.tier-filter__cell,
.type-filter__cell,
.stat-filter__cell,
.import-export__cell,
.team-display__cell {
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;

  min-width: fit-content;

  padding: var(--cell-padding);
  background-color: var(--dark2);
}

.gen-filter__wrapper,
.tier-filter__wrapper,
.type-filter__wrapper,
.stat-filter__wrapper,
.import-export__wrapper,
.team-display__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--dark1);
  box-shadow: var(--control-shadow);
  border-radius: 15px;
  
  justify-self: center;
  align-self: center;
  padding: 0.25rem;
}

/* #endregion */


/* Team display */
/* #region */

.control-panel__team {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  width: 100%;
  min-height: 100px;
  border: 1px solid white;
}

.control-panel__team-member {
  grid-row: 1 / 2;
  grid-column: span 1;
  border: 1px solid white;
}

/* Sprite */

.control-panel__team-sprite-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100px;
}

.control-panel__team-sprite {
  position: relative;
  max-height: 100px;
  width: auto;
}


/* Base stat info */

.control-panel__team-stats-list {
  border: 1px solid white;
}

/* Type info */

.control-panel__team-type-icon {
  border: 1px solid white;
}

.control-panel__team-type-icon {
  border: 1px solid white;
}

/* #endregion */

