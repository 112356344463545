/* Type select */
/* #region */

.type-filter__wrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr;
}

.type-filter__button {
  grid-row: 1 / 2;
  grid-column: 1 / 2;

  justify-self: center;
  align-self: center;
}

.type-filter__select {
  grid-row: 1 / 2;
  grid-column: 2 / 3;

  justify-self: flex-start;
  align-self: center;
  
  width: 100%;
}

.type-filter__type-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.25rem 1rem;
}

/* #endregion */

/* Stat filter */
/* #region */

.stat-filter__wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
}

.stat-filter__button {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  
  justify-self: center;
  align-self: center;
}

.stat-filter__select {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  
  justify-self: flex-start;
  align-self: center;

  width: 100%;
}

.stat-filter__base-stat-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.25rem;
}

.stat-filter__base-stat-name {
  font-family: var(--monospace);
  margin-right: 3rem;
}

/* #endregion */