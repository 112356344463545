/* Emove default appearance */
/* #region */

.numerical-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numerical-input,
.numerical-input:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;

  border: none;
  outline: none;
  background: none;
}

/* #endregion */

/* Text input */
/* #region */

.numerical-input__wrapper {
  display: flex;
  width: fit-content;
  box-sizing: content-box;

  background: var(--dark2);
  box-shadow: inset 2px 2px 2px 0 rgba(var(--dark3-components), 0.8);
}
.numerical-input__wrapper:hover,
.numerical-input__wrapper:focus-within {
  filter: brightness(1.2);
}

.numerical-input__wrapper.--left {
  margin-right: 0.5rem;
  padding-right: 0.25rem;
}

.numerical-input__wrapper.--right {
  margin-left: 0.5rem;
  padding-left: 0.25rem;
}

.numerical-input {
  font-size: 1rem;
  font-family: var(--monospace);

  color: var(--blue1);
  transition: 0.2s ease-in;
}

/* #endregion */

/* Incement/decement arrows *?
/* #region */

.numerical-input__arrow-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

.numerical-input__up-arrow,
.numerical-input__down-arrow {
  position: absolute;

  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  transform-origin: center;
}
.numerical-input__up-arrow {
  top: 3px;
  border-bottom: 5px solid var(--blue1);
}
.numerical-input__down-arrow {
  bottom: 3px;
  border-top: 5px solid var(--blue1);
}

.numerical-input__up-arrow:hover,
.numerical-input__down-arrow:hover,
.numerical-input__up-arrow:focus,
.numerical-input__down-arrow:focus 
{
  cursor: pointer;
  filter: brightness(1.2);
  transform: scale(1.2);
}

/* #endregion */