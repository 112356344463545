.team-member__wrapper {
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-template-rows: 1fr 35px;

  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
}
.team-member__wrapper:hover {
  cursor: pointer;
}

/* Add button */
/* #region */

.team-member__add {
  --add-button-width: 24px;

  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);

  position: absolute;
  top: calc(50% - var(--add-button-width) / 2);
  left: calc(50% - var(--add-button-width) / 2);
  height: var(--add-button-width);
  width: var(--add-button-width);

  transform-origin: center;
}
.team-member__add:hover,
.team-member__add:focus,
.team-member-holder__wrapper:hover .team-member__add {
  cursor: pointer;
  transform: scale(1.1);
}

.team-member__add-h,
.team-member__add-v {
  width: 100%;
  height: 100%;
  background-color: rgba(var(--green1-components), 1);
}
.team-member__add-h {
  grid-row: 2 / 3;
  grid-column: 1 / 4;
}
.team-member__add-v {
  grid-row: 1 / 4;
  grid-column: 2 / 3;
}

/* #endregion */

/* Remove button */
/* #region */

.team-member__remove-wrapper {
  grid-row: 1 / 2;
  grid-column: 3 / 4;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  --remove-button-side: 12px;
  --triangle-size-factor: calc(1 / 4);
}

.team-member__remove {
  position: relative;
  width: var(--remove-button-side);
  height: var(--remove-button-side);
  background: var(--red1);

  transition: 0.2s ease;
  transform-origin: center;
}
.team-member__remove:hover,
.team-member__remove:focus {
  transform: scale(1.2);
  filter: brightness(1.1);
  cursor: pointer;
}

/* Triangles */
/* #region */ 

.team-member__remove.--top,
.team-member__remove.--left,
.team-member__remove.--bottom,
.team-member__remove.--right {
  position: absolute;
  width: 0;
  height: 0;

  opacity: 1;
}

.team-member__remove.--top {
  top: calc((1 - var(--triangle-size-factor)) * var(--remove-button-side));
  left: calc((0.5 - var(--triangle-size-factor)) * var(--remove-button-side));

  border-left: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;
  border-right: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;

  border-bottom: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid var(--dark3);
}

.team-member__remove.--bottom {
  bottom: calc((1 - var(--triangle-size-factor)) * var(--remove-button-side));
  left: calc((0.5 - var(--triangle-size-factor)) * var(--remove-button-side));

  border-left: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;
  border-right: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;

  border-top: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid var(--dark3);
}

.team-member__remove.--left {
  left: calc((1 - var(--triangle-size-factor)) * var(--remove-button-side));
  top: calc((0.5 - var(--triangle-size-factor)) * var(--remove-button-side));

  border-top: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;
  border-bottom: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;

  border-right: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid var(--dark3);
}

.team-member__remove.--right {
  right: calc((1 - var(--triangle-size-factor)) * var(--remove-button-side));
  top: calc((0.5 - var(--triangle-size-factor)) * var(--remove-button-side));

  border-top: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;
  border-bottom: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid transparent;

  border-left: calc(var(--triangle-size-factor) * var(--remove-button-side)) solid var(--dark3);
}

/* Fade on return */
/* #region */

.team-member__return > div {
  opacity: 0;
}

/* #endregion */

/* #endregion */

/* #endregion */

/* Pokemon icon */
/* #region */

.team-member__pokemon-icon-wrapper {
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
}
.team-member__pokemon-icon-container {
  position: relative;
  width: 40px;
  height: 35px;
}
.team-member__pokemon-icon-container:hover {
  cursor: pointer;
}
.team-member__track {
  position: absolute;
  width: 30px;
  height: 15px;
  left: 5px;
  background-color: var(--light1);
  border-radius: 50%;
}
.team-member__pokemon-icon {
  top: -3px;
  left: 0;
  position: absolute;
  background-image: url('../../../../../assets/icons/pokemonicons-sheet.png');
  background-repeat: no-repeat;
}
.team-member__pokemon-icon:hover {
  cursor: pointer;
}

.team-member__remove-timer {
  position: absolute;
  top: 0;
  left: 15%;
  width: 0;
  height: 2px;
  background-color: var(--light1);
}

/* #endregion */