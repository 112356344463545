.team-column__wrapper {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: 1fr;

  position: relative;
  width: 100%;
  height: 100%;

  gap: 0.375rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

/* Individual member formatting */
/* #region */

.team-column__member-wrapper {
  grid-row: span 1;
  grid-column: span 1;

  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 40px 15ch 15ch;
  align-items: center;
  gap: 2px 6px;
  
  position: relative;
  width: 100%;
  height: 100%;

  font-size: 0.9rem;
  line-height: 1.0;
  padding: 0.125rem;

  box-sizing: border-box;
  outline: 1px solid var(--light3);
  box-shadow: 2px 2px 2px 1px rgb(0, 0, 0, 0.8);
}

.team-column__member-wrapper.--user:hover,
.team-column__member-wrapper.--user:focus-within,
.team-column__member-wrapper.--user.--relevant {
  outline: 1px solid var(--green1);
}
.team-column__member-wrapper.--enemy:hover,
.team-column__member-wrapper.--enemy:focus-within,
.team-column__member-wrapper.--enemy.--relevant {
  outline: 1px solid var(--red1);
}

.team-column__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: 0.5s ease;
}

.team-column__icon {
  grid-row: 1 / 3;
  grid-column: 1 / 2;

  display: flex;
  justify-content: center;
  align-items: center;
}
.team-column__name {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}
.team-column__typing {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}
.team-column__typing > * + * {
  margin-left: 1px;
}
.team-column__item-icon {
  grid-row: 3 / 5;
  grid-column: 1 / 2;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* 'normal' mode */
/* #region */

.team-column__ability {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
}
.team-column__item {
  grid-row: 4 / 5;
  grid-column: 2 / 3;
}

.team-column__move1 {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
}
.team-column__move2 {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}
.team-column__move3 {
  grid-row: 3 / 4;
  grid-column: 3 / 4;
}
.team-column__move4 {
  grid-row: 4 / 5;
  grid-column: 3 / 4;
}

/* #endregion */

/* 'stat' mode */
/* #region */

.team-column__nature {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
}

.team-column__spreads {
  grid-row: 2 / 3;
  grid-column: 3 / 4;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.team-column__stats {
  grid-row: 3 / 5;
  grid-column: 2 / 4;

  display: grid;
  grid-template-columns: repeat(3, minmax(7ch, 1fr));
  grid-template-rows: 1fr 1fr;

  position: relative;
  width: 100%;
  height: 100%;
}

.team-column__stat-wrapper {
  grid-row: span 1;
  grid-column: span 1;
}

/* #endregion */

/* Need to exclude the wrapper, otherwise the underline text-decoration will be passed down to descendants and cannot be removed */
.--relevant:not(.team-column__member-wrapper) {
  font-weight: 600;
  text-decoration: underline;
}
.--irrelevant:not(.team-column__member-wrapper) {
  color: var(--dark1);
}
.--irrelevant.team-column__icon,
.--irrelevant.team-column__type-icon,
.--irrelevant.team-column__item-icon,
.--irrelevant.team-column__typing {
  filter: brightness(0.5);
}

/* #endregion */

/* PopupSearch */
/* #region */

.popup-search__wrapper {
  display: flex;
  flex-direction: column;
}

.popup-search__search-bar {
  background-color: var(--dark3);
  margin-bottom: 0.25rem;
}

.popup-search__results > * + * {
  margin-top: 0.25rem;
}

.popup-search__result {
  background-color: var(--dark3);
  border-bottom: 2px solid var(--dark2);
  padding: 0.25rem;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8);
  transition: 0.3s ease;
}
.popup-search__result:hover {
  filter: brightness(1.3);
  cursor: pointer;
}

/* Highlight first popup-search__result */
.popup-search__result:first-of-type {
  filter: brightness(1.3);
}

.popup-spread__slider-wrapper {
  display: flex;
  align-items: center;
}

.popup-spread__slider {
  display: flex;
  align-items: center;
}

/* #endregion */